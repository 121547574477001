import { PostFormData } from "../../utils/http-client";

// Combined Monolith Transformed
export const getCombinedQuestions = function (data, headers) {
    return PostFormData(`/getQuestions/combined`, 'POST', data, true, headers);
}
export const getQuestions = function (data,headers) {
    return PostFormData(`/getQuestions`, 'POST', data, true,headers);
}
// Business Owner Policy Monolith Transformed
export const getBusinessOwnerPolicyQuestions = function (data, headers) {
    return PostFormData(`/getQuestions/businessownerpolicy`, 'POST', data, true, headers);
}

// Dwelling Policy Monolith Transformed
export const getDwellingPolicyQuestions = function (data, headers) {
    return PostFormData(`/getQuestions/dwellingpolicy`, 'POST', data, true, headers);
}

// Insurance Basics Monolith Transformed
export const getInsuranceBasicsQuestions = function (data, headers) {
    return PostFormData(`/getQuestions/insurancebasics`, 'POST', data, true, headers);
}

// Commercial Policy Monolith Transformed
export const getCommercialPolicyQuestions = function (data, headers) {
    return PostFormData(`/getQuestions/commercialpolicy`, 'POST', data, true, headers);
}

// Other Coverages Monolith Transformed
export const getOtherCoveragesQuestions = function (data, headers) {
    return PostFormData(`/getQuestions/othercoverages`, 'POST', data, true, headers);
}

// Adjusting Losses Monolith Transformed
export const getAdjustingLossesQuestions = function (data, headers) {
    return PostFormData(`/getQuestions/adjustinglosses`, 'POST', data, true, headers);
}
