import { ReactNode } from 'react'
import { ReactComponent as Angular } from '../assets/icons/angular.svg'
import { ReactComponent as CSS } from '../assets/icons/css-3.svg'
import { ReactComponent as Django } from '../assets/icons/dj.svg'
import { ReactComponent as Insurance } from '../assets/icons/insurance.svg'
import { ReactComponent as Dwelling } from '../assets/icons/dwelling.svg'
import { ReactComponent as Coverages } from '../assets/icons/coverages.svg'
import { ReactComponent as Commercial } from '../assets/icons/commercial.svg'
import { ReactComponent as Business } from '../assets/icons/business.svg'
import { ReactComponent as AdjustinLoss } from '../assets/icons/adjustingloss.svg'
import { ReactComponent as BulbIcon } from '../assets/icons/bulb.svg'

type QuizTopic = {
  title: string
  icon: ReactNode
  disabled?: boolean
}

export const quizTopics: QuizTopic[] = [
  // {
  //   title: 'React',
  //   icon: <ReactIcon />,
  // },
  // {
  //   title: 'JavaScript',
  //   icon: <JavaScript />,
  // },
  // {
  //   title: 'Python',
  //   icon: <Python />,
  // },

  {
    title: 'Adjusting Losses',
    icon: <BulbIcon />,
  },
  {
    title: 'Business Owner Policy',
    icon: <AdjustinLoss />,
  },
  {
    title: 'Commercial Policy',
    icon: <Commercial />,
  },
  {
    title: 'Dwelling Policy',
    icon: <Dwelling />,
  },
  {
    title: 'Insurance Basics',
    icon: <Insurance />,
  },
  {
    title: 'Other Coverages',
    icon: <Coverages />,
  },
  // {
  //   title: 'Gatsby',
  //   icon: <Gatsby />,
  //   disabled: true,
  // },
  // {
  //   title: 'Angular',
  //   icon: <Angular />,
  //   disabled: true,
  // },
  // {
  //   title: 'Django',
  //   icon: <Django />,
  //   disabled: true,
  // },
  // {
  //   title: 'CSS',
  //   icon: <CSS />,
  //   disabled: true,
  // },
  // {
  //   title: 'Kotlin',
  //   icon: <Kotlin />,
  //   disabled: true,
  // },
  // {
  //   title: 'Laravel',
  //   icon: <Laravel />,
  //   disabled: true,
  // },
]
