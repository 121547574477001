import React, { useEffect } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import avatarIcon from '../assets/chat-bot.svg'; // Adjust the path as necessary

const Billing = () => {
    const accountId = localStorage.getItem('accountId');
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div style={{ margin: 0, backgroundColor: '#292929', color: 'white', minHeight: '100vh' }}>
            <Container className="center-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', borderBottom: '2px solid grey' }}>
                <div>
                <img src={avatarIcon} alt="Logo" style={{ width: '70px', height: '70px', margin: '30px 0 0 0' }} /> {/* Adjust width and height to double the size */}
                <h1 className="mt-5 mb-4">CHOOSE YOUR PLAN</h1>
                    <h3>Unlock Claims Cortex AI</h3>
                </div>

                <Nav variant="tabs" className="mb-4 home-nav justify-content-center">
                <h5 className="text-center mb-4" style={{ margin: '30px 0', fontFamily: 'Helvetica, Arial, sans-serif', fontWeight: 'bold', lineHeight: '1.5', color: 'lightgrey' }}>
                Elevate your business with a dynamic profile and watch your sales reach new heights.
              </h5>
                </Nav>

                <Row className="col-lg-10 mx-auto my-4 justify-content-end align-items-center d-flex">
                    {/* Additional content can be added here */}
                </Row>
            </Container>
            <div style={{ padding: '50px 0'}}>
            <stripe-pricing-table
                pricing-table-id="prctbl_1PlOspKwxmdXHYLk5uGRCIZ1"
                publishable-key="pk_test_51NIFBDKwxmdXHYLkKIFfC9wEdqwX4N7XdJkQggO0Kdg2c66UR4mXTBAyLPXVICPTn6oF7ew0Wjr9q7E8j553FtDe00kIoCvWyG"
                client-reference-id={accountId}
            ></stripe-pricing-table>
            
            </div>
           
        </div>
    );
};

export default Billing;
