import React from 'react';
import { Navigate } from 'react-router-dom';

const SemiPrivateRoute = ({ children }) => {
  localStorage.setItem('IsQuiz', true.toString());
  console.log('IsQuiz', localStorage.getItem('IsQuiz'));
  localStorage.setItem('showTrainingTerminal', false.toString());

  const authToken = localStorage.getItem('authToken');
  return authToken  ? children : <Navigate to="/login" />;

};

export default SemiPrivateRoute;
