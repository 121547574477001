import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../components/Login';
import Billing from '../components/Billing';
import Quiz from '../components/Quiz';
import CreateAccount from '../components/CreateAccount';
import PrivateRoute from './PrivateRoute';
import EditAccount from '../components/EditAccount';
import EstimateGenerator from '../components/EstimateGenerator';
import PublicRoute from './PublicRoute';
import SemiPrivateRoute from './SemiPrivateRoute';
import MaintenancePage from '../components/Maintenance';
import Stepper from '../components/Stepper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../global.css'; // Import the custom CSS
import WelcomePage from '../components/WelcomePage';
const Navigation = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        } />
        <Route path="/createAccount" element={
          <PublicRoute>
            <CreateAccount />
          </PublicRoute>
        } />
        <Route path="/editAccount" element={
          <PrivateRoute>
            <EditAccount/>
          </PrivateRoute>
        } />
        <Route path="/estimates" element={
          <PrivateRoute>
            <EstimateGenerator/>
          </PrivateRoute>
        } />
          <Route path="/billing" element={
          <PrivateRoute>
            <Billing/>
          </PrivateRoute> 
        } />
        <Route path="/training" element={
          <SemiPrivateRoute>
            <Quiz/>
          </SemiPrivateRoute>
        } />
        <Route path="/app" element={
          <PrivateRoute>
            <Stepper />
          </PrivateRoute>
        } />
        {/* <Route path="/app1" element={
          <SemiPrivateRoute>
            <Stepper />
          </SemiPrivateRoute>
        } /> */}
        <Route path="/" element={
          <PublicRoute>
            <WelcomePage />
          </PublicRoute>
        } />
        {/* <Route path="/maintenance" element={
          <PublicRoute>
            <MaintenancePage />
          </PublicRoute>
        } /> */}
        {/* <Route path="/summary" element={
          <PrivateRoute>
            <Summary />
          </PrivateRoute>
        } />
        <Route path="/edit" element={
          <PrivateRoute>
            <EditMail />
          </PrivateRoute>
        } /> */}
      </Routes>
      <ToastContainer />
    </>
  );
};

export default Navigation;
