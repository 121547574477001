import { Topic } from '.'
import BrandLogo from '../../assets/images/brand-logo.jpg'
import Car from '../../assets/images/car.jpg'
import Dish from '../../assets/images/dish.jpg'
import Mosque from '../../assets/images/mosque.jpg'
import Place from '../../assets/images/place.jpg'
import Reptile from '../../assets/images/reptile.jpg'
import combinedMonolith from '../../../othercoverages_monolith_transformed.json'; // Adjust the path as needed
import { getOtherCoveragesQuestions } from '../../services/quiz/quiz.service';

var count = 0;
async function fetchQuestions() {
  try {
    const response = await getOtherCoveragesQuestions({}, {});
    count = response.data['OtherCoverages'].length;
    return response.data['OtherCoverages'] ;
  } catch (error) {
    console.error('Error fetching questions:', error);
    return getOtherCoveragesQuestions;
  }
}

export const otherCoverages: Topic = {
  topic: 'Other Coverages',
  level: 'Beginner',
  questions: await fetchQuestions(),
  totalQuestions: count,
  totalScore: count * 10,
  totalTime: count * 30
};