import { Topic } from '.'
import BrandLogo from '../../assets/images/brand-logo.jpg'
import Car from '../../assets/images/car.jpg'
import Dish from '../../assets/images/dish.jpg'
import Mosque from '../../assets/images/mosque.jpg'
import Place from '../../assets/images/place.jpg'
import Reptile from '../../assets/images/reptile.jpg'
import combinedMonolith from '../../../combined_monolith.json'; // Adjust the path as needed
import { getBusinessOwnerPolicyQuestions  } from '../../services/quiz/quiz.service';

var count = 0;

async function fetchQuestions() {
  try {
    const response = await getBusinessOwnerPolicyQuestions ({}, {});
    count = response.data['BusinessOwner'].length;
    return response.data['BusinessOwner'] ;
  } catch (error) {
    console.error('Error fetching questions:', error);
    return [];
  }
}

export const businessOwnerPolicy: Topic = {
  topic: 'Business Owner Policy',
  level: 'Beginner',
  questions: await fetchQuestions(),
  totalQuestions: count,
  totalScore: count * 10,
  totalTime: count * 30
};