import { useState } from 'react'
import { ThemeProvider } from 'styled-components'

import Main from './Main'
import ToggleTheme from './ui/ToggleTheme'
import QuizProvider from '../context/QuizProvider'
import { GlobalStyles } from '../styles/Global'
import { themes } from '../styles/Theme'
import ChatbotWidgetTraining from '../components/chatbot/ChatbotWidgetTraining';

function Quiz() {
  const [currentTheme, setCurrentTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme')
    return savedTheme || 'light'
  })

  const toggleTheme = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    setCurrentTheme(checked ? 'dark' : 'light')
    localStorage.setItem('theme', checked ? 'dark' : 'light')
  }

  const theme = currentTheme === 'light' ? themes.light : themes.dark
  let isQuiz =  localStorage.getItem('IsQuiz') === true.toString();
  const showTrainingTerminal =  localStorage.getItem('showTrainingTerminal') === true.toString();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <QuizProvider>
        <ToggleTheme
          onChange={toggleTheme}
          currentTheme={currentTheme}
          checked={currentTheme === 'dark'}
          id="toggleTheme"
          value="theme"
        />
        <Main />
        <ChatbotWidgetTraining /> 
      </QuizProvider>
    </ThemeProvider>
  )
}

export default Quiz
