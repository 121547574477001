import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function WelcomePage() {
  return (
    <Container fluid className="vh-100 d-flex flex-column justify-content-center align-items-center">
      <Row className="text-center">
        <Col>
          <h1 className="display-4">Welcome to Claims Cortex</h1>
          <h5 style={{color: "grey"}}>Version 1.0</h5>

          <p className="lead mt-4">
            Claims Cortex is your ultimate solution for streamlined and efficient claims processing. 
            Harness the power of AI to revolutionize your workflow and boost your productivity.
          </p>
          <Button variant="primary" className="mt-3 px-4 py-2" href="/login" style={{ background: "linear-gradient(90deg, rgba(189, 38, 249, 1) 32%, rgba(82, 52, 226, 1) 100%)" }} >
            Login
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default WelcomePage;
