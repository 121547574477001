import React, { useState } from "react";
import { Button, Col, Container, Image, Nav } from "react-bootstrap";
import { toast } from 'react-toastify';
import logo from "../assets/logo-2.png";
import FileUploader from "./FileUploader";
import {  generateEstimate } from "../services/uploads/upload.service";

const EstimateGenerator = ( ) => {
  const [estimatePdf, setEstimatePdf] = useState(null);
  const [loading, setLoading] = useState(false);

  const tokenCount = localStorage.getItem('tokenCount');

  const handlePdfNext = async () => {
    const toastId = toast.loading("Generating Summary of your argument"); // Show loading toast
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("estimate_pdf", estimatePdf);
      
      const headers = {
        "Content-Type": "multipart/form-data" ,
        "Authorization": localStorage.getItem("authToken")
      };

      const response = await generateEstimate(formData, headers) ;
      
      setLoading(false);
      toast.update(toastId, { render: "Summary of your argument generated Successfully", type: "success", isLoading: false, autoClose: 5000 });
    } catch (error) {
      console.error("Error uploading files:", error);
      setLoading(false);
      toast.update(toastId, { render: "Error generating summary. Try again later", type: "error", isLoading: false, autoClose: 5000 });
    }
  };

  return (
    <Container className="my-5 d-flex justify-content-center flex-column align-items-center">
      <Col
        lg={6}
        className="mx-auto text-center mb-3"
        style={{
          borderBottom: "1px solid",
          borderColor: "rgb(255, 255, 255,0.2)",
        }}
      >
        <Image src={logo} />
        <h1 className="mt-5 mb-4">UPLOAD FILES</h1>
        <h5 className="mt-5 mb-4">Cortex Tokens: {tokenCount}</h5>

      </Col>

      <Nav variant="tabs" className="mb-4 home-nav">
        <Nav.Item>
          <Nav.Link
            active={true}
            className="me-2"
          >
            Upload PDFs
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <FileUploader
        title="Upload Files of Property Estimates to Generate an Xactimate Equivalenet Estimate"
        fileType="application/pdf"
        category="property"
        setFile={setEstimatePdf}
        file={estimatePdf}
      />

      <Col lg={10} className="mx-auto my-4 d-flex justify-content-center align-items-center">
          <Button
            className="step-button border-0 px-4 py-2 mx-auto"
            onClick={handlePdfNext}
          >
            <p className="m-0">Generate Estimate</p>
          </Button>
      </Col>
    </Container>
  );
};

export default EstimateGenerator;
