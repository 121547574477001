import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
  // const authToken = localStorage.getItem('authToken');
  // return authToken ? <Navigate to="/" /> : children;
    localStorage.setItem('IsQuiz', false.toString());
    localStorage.setItem('showTrainingTerminal', false.toString());

    console.log('Navigation');

  return children
};

export default PublicRoute;
