import { generalKnowledge } from './generalKnowledge'
import { businessOwnerPolicy } from './BusinessOwnerPolicy'
import { adjustingLosses } from './AdjustingLosses'
import { commercialPolicy } from './CommercialPolicy'
import { dwellingPolicy } from './DwellingPolicy'
import { insuranceBasics } from './InsuranceBasics'
import { otherCoverages } from './OtherCoverages'

import { javascript } from './javascript'
import { python } from './python'
import { react } from './react'

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

type Choice = string
type CorrectAnswers = string[]

export type Question = {
  question: string
  choices: Choice[]
  type: 'MCQs' | 'MAQs' | 'boolean'
  correctAnswers: CorrectAnswers
  score: number
  code?: string
  image?: string
}

export type Topic = {
  topic: string
  level: string
  totalQuestions: number
  totalScore: number
  totalTime: number
  questions: Question[]
}

export const quiz: Record<string, Topic> = {
  JavaScript: javascript,
  React: react,
  Python: python,
  'General Knowledge': generalKnowledge,
  'Business Owner Policy': businessOwnerPolicy,
  'Adjusting Losses': adjustingLosses,
  'Commercial Policy': commercialPolicy,
  'Dwelling Policy': dwellingPolicy,
  'Insurance Basics': insuranceBasics,
  'Other Coverages': otherCoverages,


}
