import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../global.css'; // Import the custom CSS for consistent styling
import { getAccount, updateAccount } from "../services/account/account.service";
import AIImage from '../assets/AI_Img.png';
import { useNavigate } from 'react-router-dom';

const EditAccount = () => {
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        oldPassword: '',
        newPassword: '',
        verifyNewPassword: '',
        tokenCount: 0,
    });

    const navigate = useNavigate();
    const authToken = localStorage.getItem('authToken');
    const headers = {
        'Authorization': `${authToken}`,
        'Content-Type': 'application/json',
        'accountId': localStorage.getItem('accountId')
    };

    useEffect(() => {
        handleGetUser();
    }, []);

    const handleGetUser = async () => {
        const isAuthenticated = authToken && authToken.trim() !== '';
        if (isAuthenticated) {
            const accountId = localStorage.getItem('accountId');
            const requestData = { accountId: accountId };

            try {
                const response = await getAccount(JSON.stringify(requestData), headers);

                if (response && response.data) {
                    setProfileData({
                        ...profileData,
                        firstName: response.data["account"].FirstName || profileData.firstName,
                        lastName: response.data["account"].LastName || profileData.lastName,
                        email: response.data["account"].Email || profileData.email,
                        tokenCount: response.data["account"].Token || profileData.tokenCount,
                    });
                } else {
                    console.error("Invalid response data:", response);
                }
            } catch (error) {
                console.error("Failed to fetch user data:", error);
            }
        } else {
            navigate('/login');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (profileData.newPassword !== profileData.verifyNewPassword) {
                alert('Passwords do not match');
                return;
            }

            const isAuthenticated = authToken && authToken.trim() !== '';
            if (isAuthenticated) {
                const response = await updateAccount(JSON.stringify(profileData), headers);

                if (response.status === 200) {
                    alert('Account updated successfully');
                } else {
                    alert('Failed to update account');
                }
            } else {
                navigate('/login');
                return;
            }
        } catch (error) {
            console.error('Error during account creation:', error);
        }
    };

    return (
        <Container className="mt-5 container-profile" style={{ marginBottom: '120px', marginTop: '120px' }}>
            <Row className="justify-content-center" style={{ padding: "0" }}>
                <Col md={6}>
                    <Card className="card-profile">
                        <Card.Body>
                            <Card.Title className="text-center card-title">Edit Profile</Card.Title>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formFirstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        value={profileData.firstName}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formLastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        value={profileData.lastName}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formOldPassword">
                                    <Form.Label>Old Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="oldPassword"
                                        value={profileData.oldPassword}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formNewPassword">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="newPassword"
                                        value={profileData.newPassword}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formVerifyNewPassword">
                                    <Form.Label>Verify New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="verifyNewPassword"
                                        value={profileData.verifyNewPassword}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formTokenCount" className="token-count-container">
                                    <Form.Label className="token-label">AI Tokens</Form.Label>
                                    <span className="token-count">{profileData.tokenCount}</span>
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="w-100 mt-3"
                                    style={{ background: "linear-gradient(90deg, rgba(189, 38, 249, 1) 32%, rgba(82, 52, 226, 1) 100%)" }}
                                >
                                    Save Changes
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="p-4" style={{ height: '100%' }}>
                        <img
                            src={AIImage}
                            alt="Promotional Image"
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default EditAccount;