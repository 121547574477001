import { PostFormData } from "../../utils/http-client";

export const createAccount = function (data,headers) {
    return PostFormData(`/createAccount`, 'POST', data, true,headers);
}
export const updateTokens = function (data,headers) {
    return PostFormData(`/updateTokens`, 'POST', data, true,headers);
}
export const getAccount = function (data,headers) {
    return PostFormData(`/getAccount`, 'POST', data, true,headers);
}
export const updateAccount = function (data,headers) {
    return PostFormData(`/updateAccount`, 'POST', data, true,headers);
}
export const logout = function (data,headers) {
    return PostFormData(`/logout`, 'POST', data, true,headers);
}
export const login = function (data,headers) {
    return PostFormData(`/login`, 'POST', data, true,headers);
}